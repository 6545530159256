.activeButton {
    color:#042315 !important;
    border-bottom: 2px solid #ff462d !important;
    /* background-color: #fff; */
    font-weight: 500 !important;
    border-radius: 0px;
}

.activeButton:hover {
    color:#042315 !important;
    border-bottom: 2px solid #ff462d;
    background-color: #f2f1ee !important; 
    font-weight: 500 !important;
    border-radius: 0px !important;
}



#home-tab{
    color:#686868;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0px;
    border-bottom: 2px solid #e0e0e0;
    border-radius: 0px;
}

#home-tab:hover{
    background-color: #f2f1ee;
    border-bottom: 2px solid #8d8d8d;
    border-radius: 0px;
}

#home-tab:focus{
    background-color: #f2f1ee;
    border-radius: 0px;
    /* border-color: #fff; */
}

