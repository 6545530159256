#table-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: #f5f5f5;
    scrollbar-width: thin;
}

#table-scroll::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #f5f5f5;
    scrollbar-width: thin;
}

#table-scroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #04231511;
    scrollbar-width: thin;
}

.ant-card-body {
    width: 100%;
    height: 100%;
    padding: 20px !important;
}